<template>
    <v-row justify="center">
      <v-dialog
        v-if="resolutionScreen >= 500"
        v-model="shower"
        scrollable
        persistent
        max-width="600px"
      >
        <v-card>
            <v-card-title
            >{{ $t("migratetogg") }}<v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35"
              >mdi-progress-upload</v-icon
            >
          </v-card-title>
          <!-- <v-card-title
            >{{ $t("fileduplicate.headerfileduplicate") }}<v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35"
              >mdi-progress-upload</v-icon
            >
          </v-card-title> -->
          <v-divider></v-divider>
          <v-card-text
            style="height: 400px;"
            class="pa-0 mt-1"
            v-if="listmove.length > 0"
          >
            <!-- <v-flex v-if="createprogress === true">
                <v-layout justify-center>
                    <br />
                    <v-progress-circular
                    class="mt-6 mb-6 pt-6 pb-6"
                    :size="50"
                    :dark="color.darkTheme"
                    :color="color.theme"
                    indeterminate
                    ></v-progress-circular>
                </v-layout>
            </v-flex> -->
            <v-list v-for="(item, i) in listmove" :key="i">
                <v-list-item>
                <!-- <v-list-item-action>
                    <v-checkbox
                    v-model="selectedduplicate"
                    :value="item"
                    class="pl-1"
                    >
                    </v-checkbox>
                </v-list-item-action> -->
                <v-list-item-action>
                    <v-icon
                        v-if="item.data_type === 'folder'"
                        :color="'#ffc107'"
                        large
                        >folder</v-icon
                    >
                    <v-icon
                        v-else
                        :color="fn_seticon(item.data_name.split('.')[1])[1]"
                        large
                        >{{ fn_seticon(item.data_name.split('.')[1])[0] }}</v-icon
                    >
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="pl-4">
                    {{ listmove[i].data_name }}
                    </v-list-item-title>
                </v-list-item-content>
                <!-- <v-progress-circular
                :rotate="90"
                :size="35"
                :width="3"
                :value="item.value"
                color="teal"
                style="margin-left:25px"
              >
                <v-list-item-title v-text="item.value"></v-list-item-title>
              </v-progress-circular> -->
                </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn
              :disabled="createprogress"
              :loading="createprogress"
              outlined
              @click="migrat_duplicate()"
              color="success"
            >
              <span>{{$t('logo_feature.change_consent')}}</span>
            </v-btn>
            <v-btn
              :disabled="createprogress"
              outlined
              @click="$emit('skip')"
              color="error"
            >
              <span>{{ $t("fileduplicate.fileskip") }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- หน้าจอมือถือ -->
      <v-dialog
        v-else
        v-model="shower"
        scrollable
        persistent
        :max-width="maxWidthOnMobile"
        content-class="rounded-lg"
      >
        <v-card>
          <v-card-text class="pa-5">
            <v-layout class="pt-2 pb-n1">
              <v-flex d-flex justify-center class="">
                <span :style="headerPage">
                  {{ $t("migratetogg") }}</span>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text
            style="height: 400px;"
            class="pa-0 mt-1"
            v-if="listmove.length > 0"
          >
            <div>
              <v-list v-for="(item, i) in listmove" :key="i">
                <v-list-item class="mt-n4">
                    <v-list-item-action>
                      <v-icon
                        v-if="item.data_type === 'folder'"
                        :color="'#ffc107'"
                        large
                        >folder</v-icon
                    >
                    <v-icon
                        v-else
                        :color="fn_seticon(item.data_name.split('.')[1])[1]"
                        large
                        >{{ fn_seticon(item.data_name.split('.')[1])[0] }}</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ listmove[i].data_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
          <div class="text-center my-4">
            <v-btn
              class="mr-1"
              :disabled="createprogress"
              :loading="createprogress"
              outlined
              @click="migrat_duplicate()"
              color="success"
            >
              <span>{{$t('logo_feature.change_consent')}}</span>
            </v-btn>
            <v-btn
              :disabled="createprogress"
              outlined
              @click="$emit('skip')"
              color="error"
            >
              <span>{{ $t("fileduplicate.fileskip") }}</span>
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import VueCookies from "vue-cookies";
  import { mapState, mapGetters } from "vuex";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";

    
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  export default {
    props: ["show", "listmove","listmove_unduplicate","listmove_duplicate"],
    data: function() {
      return {
        createprogress:false,
        // checkfirstloopchoice: false,
        // listmove:[],
        moredetail: false,
        selectedduplicate: [],
        uploadfolder:false
      };
    },
    computed: {
      ...mapState(["username", "authorize", "account_active", "color"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
      ]),
      shower: {
        get() {
          if (this.show === true) {
            console.log(this.listmove);
            this.selectedduplicate = [...this.listmove_duplicate];
          } else {
            this.selectedduplicate = [];
            // this.checkfirstloopchoice = false;
          }
          return this.show;
        },
      },
      // สำหรับการเลือกทั้งหมด
      selectedAll: {
        set(val) {
          this.selectedduplicate = [];
          if (val) {
            for (let i = 1; i <= this.listmove_duplicate.length; i++) {
              this.selectedduplicate.push(this.listmove_duplicate[i - 1]);
            }
          }
        },
        get () {
          if (this.show === false) {
            return false;
          } else if (this.selectedduplicate.length === this.listmove_duplicate.length) {
            return true;
          }
        },
      },
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      // style อักษร header ของ mobile
      headerPage() {
        return (
          "color:" +
          this.color.theme +
          ";" +
          "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
        );
      },
      // style ปุ่ม ของ mobile
      btnAction() {
        return "font-size: 16px; font-weight: lighter;";
      },
      // style อักษรใน chip ของ mobile
      headerPageMobile() {
        return "color:white;" + "font-size: 16px; line-height: 24px;";
      },
      // style chip ของ mobile
      headerChipPage() {
        return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;";
      },
      // ความกว้างของ dialog ของ ipad และ mobile
      maxWidthOnMobile() {
        if (this.resolutionScreen >= 400) {
          return 600;
        } else {
          return 346;
        }
      },
    },
    methods: {
        // เซ็ต icon
        fn_seticon(parameter) {
        let dataicon;
        if (parameter === "xlsx" || parameter === "xls") {
            dataicon = ["mdi-file-excel-outline", "green"];
        } else if (parameter === "pptx" || parameter === "ppt") {
            dataicon = ["mdi-file-powerpoint-outline", "orange"];
        } else if (parameter === "docx" || parameter === "doc") {
            dataicon = ["mdi-file-word-outline", "primary"];
        } else if (parameter === "zip") {
            dataicon = ["mdi-zip-box-outline", "blue-grey"];
        } else if (parameter === "pdf") {
            dataicon = ["mdi-file-pdf-outline", "red"];
        } else if (parameter === "xml") {
            dataicon = ["mdi-file-code-outline", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
            dataicon = ["mdi-note-text-outline", "blue-grey"];
        } else if (
            parameter === "jpeg" ||
            parameter === "jpg" ||
            parameter === "png" || 
            parameter === "gif"
        ) {
            dataicon = ["mdi-file-image-outline", "blue-grey"];
        } else if (
            parameter === "avi" ||
            parameter === "mpeg" ||
            parameter === "mov" ||
            parameter === "mp4" ||
            parameter === "mkv" ||
            parameter === "wmv" ||
            parameter === "3gp" ||
            parameter === "flv"
        ) {
            dataicon = ["mdi-file-video-outline", "purple"];
        } else if (parameter === "shortcut") {
            dataicon = ["mdi-file-link-outline", "pink"];
        } else {
            dataicon = ["mdi-file-outline", "black"];
        }
        return dataicon;
        },
        async migrat_duplicate(){
            this.createprogress = true;
             for(let i=0; i < this.listmove.length; i++){
               let payload = {
               folder_id: this.dataCitizenProfile.my_folder_id,
               user_id: this.dataCitizenProfile.user_id,
               account_id: this.dataAccountId,
               data_id: this.listmove[i].data_id,
               data_type: this.listmove[i].data_type,
               file_duplicate: "create_copy",
               };
               let auth = await gbfGenerate.generateToken();
               console.log("auth", auth.code);
               await this.axios
               .post(
                   process.env.VUE_APP_MIGRATE +
                   "/api/google/drive/backup",
                   payload,
                   { headers: { Authorization: auth.code } }
               )
               .then((response) => {
                   console.log("responsedก่อนstatusok", response);
                   if (response.data.status === "OK") {
                       console.log("responsed ok", response);
                   }
               });
                if(i == this.listmove.length - 1){
                    this.createprogress = false;
                    Toast.fire({
                        icon: "success",
                        title: this.$t('migratesuccess'),
                    });
                   this.$emit("closedialog")
                }
               }
    
        },
        // async migrat_unduplicate(){
        //     for(let i=0; i < this.listmove_unduplicate.length; i++){
        //     let payload = {
        //         folder_id: this.dataCitizenProfile.my_folder_id,
        //         user_id: this.dataCitizenProfile.user_id,
        //         account_id: this.dataAccountId,
        //         data_id: this.listmove_unduplicate[i].data_id,
        //         data_type: this.listmove_unduplicate[i].data_type,
        //         file_duplicate: "create_copy",
        //         // file_duplicate: "unique foldername",
        //     };
        //         let auth = await gbfGenerate.generateToken();
        //         console.log("auth", auth.code);
        //         this.axios
        //         .post(
        //             process.env.VUE_APP_MIGRATE +
        //             "/api/google/drive/backup",
        //             payload,
        //             { headers: { Authorization: auth.code } }
        //         )
        //         .then((response) => {
        //             console.log("responsedก่อนstatusok", response);
        //             if (response.data.status === "OK") {
        //             console.log("responsed ok", response);
        //             }
        //         });
        //     }
        // },
    },
  };
  </script>
  
  <style></style>
  